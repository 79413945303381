<template>
  <div class="histories p-4">
    <AsyncList
      without-loader
      class="history-list"
    >
      <b-container fluid="xl">
        <div class="d-flex flex-column">
          <div class="d-flex mb-3">
            <h3 class="title mb-0">
              Истории
            </h3>
            <div class="ml-auto">
              <router-link
                :to="'/stories/story-create/'"
              >
                <b-button
                  variant="primary"
                  :type="$const.PRIMARY_BUTTON"
                  size="sm"
                >
                  Новая история
                </b-button>
              </router-link>
            </div>
          </div>
          <div class="d-flex mb-3">
            <b-form-radio
              v-model="isActive"
              :value="true"
            >
              <span class="crm-form-checkbox-text">
                Только активированные
              </span>
            </b-form-radio>

            <b-form-radio
              v-model="isActive"
              :value="false"
              class="ml-3"
            >
              <span class="crm-form-checkbox-text">
                Только деактивированные
              </span>
            </b-form-radio>
          </div>
        </div>
        <div
          v-if="!isLoading && !filteredStories?.length"
          class="mt-5"
        >
          Список историй пуст
        </div>
        <b-table
          v-if="!isLoading && filteredStories.length"
          class="bg-white m-0"
          bordered
          :class="{'bg-grey': !isActive}"
          :fields="isActualFields"
          :items="filteredStories"
        >
          <template #cell(coverImageFile)="row">
            <img
              :src="row.item.coverImageFile"
              width="50"
              height="50"
            >
          </template>
          <template #cell(activate)="row">
            <b-button
              size="sm"
              class="btn-text"
              :variant="isActive? 'outline-danger':'outline-primary'"
              :type="$const.PRIMARY_BUTTON"
              @click="clickMethod(row)"
            >
              {{ isActive? 'Деактивировать': 'Активировать' }}
            </b-button>
          </template>
          <template #cell(actions)="row">
            <router-link
              :to="`/stories/story-info/${row.item.id}`"
            >
              <b-button
                size="sm"
                variant="outline-primary"
                :type="$const.PRIMARY_BUTTON"
              >
                <b-icon icon="pencil-square" />
              </b-button>
            </router-link>
            <b-button
              size="sm"
              variant="outline-danger"
              :type="$const.PRIMARY_BUTTON"
              class="ml-3"
              @click="openDeleteModalHistory(row.item.id)"
            >
              <b-icon icon="trash" />
            </b-button>
          </template>
        </b-table>
        <b-skeleton-table
          v-if="isLoading"
          :rows="20"
          :columns="3"
          :table-props="{ bordered: true, striped: true }"
        />
      </b-container>
    </AsyncList>
  </div>
</template>
<script>
import AsyncList from '@/components/AsyncList';
import { APP_DOCTIS_FILE_SERVER_URL } from '@/app.config';
import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';
import { storiesService } from '@/services';
import { showCustomMessage } from '@/helpers/messages';

export default {
  name: 'StoriesList',

  components: {
    AsyncList,
  },
  data() {
    return {
      isLoading: false,
      stories: [],
      APP_DOCTIS_FILE_SERVER_URL,
      isActive: true,
      titleActive: 'erfgnfgnfgn',
      fieldsActive: [
        {
          key: 'coverImageFile',
          label: 'Обложка',
          class: 'td-img',
        },
        {
          key: 'title',
          label: 'Заголовок',
          class: 'td-title',
        },
        {
          key: 'activate',
          label: 'Деактивация',
          class: 'td-activate',
        },
        {
          key: 'actions',
          label: '',
          class: 'td-button',
        },
      ],
      fieldsDeactive: [
        {
          key: 'coverImageFile',
          label: 'Обложка',
          class: 'td-img',
        },
        {
          key: 'title',
          label: 'Заголовок',
          class: 'td-title',
        },
        {
          key: 'activate',
          label: 'Активация',
          class: 'td-activate',
        },
      ],
    };
  },
  computed: {
    filteredStories() {
      return this.stories.filter((story) => story.isActive === this.isActive);
    },
    isActualFields() {
      return this.isActive ? this.fieldsActive : this.fieldsDeactive;
    },
  },
  watch: {
    async isActive() {
      await this.fetchStories();
    },
  },
  async created() {
    await this.fetchStories();
  },
  methods: {
    async fetchStories() {
      this.isLoading = true;
      try {
        const { stories } = await storiesService.getStoriesList({});

        const storiesWithImages = await Promise.all(
          stories.map(async (story) => {
            const coverImageFile = await storiesService.getStoryImage(story.coverImageFile);
            return { ...story, coverImageFile };
          }),
        );

        this.stories = storiesWithImages;
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    openDeleteModalHistory(id) {
      uiService.showModal(MODALS.STORY_DELETE_MODAL, {
        name: 'StoryDeleteModal',
        props: {
          storyId: id,
        },
        actions: {
          delete: async (storyId) => {
            try {
              await storiesService.deleteStory(storyId);
              await this.fetchStories();
              showCustomMessage('success', 'История успешно удалена');
            } catch (e) {
              console.log(e);
            }
          },
        },
      });
    },
    async deactivateHistory(storyId) {
      this.isLoading = true;
      try {
        await storiesService.deactivateStory(storyId);
        showCustomMessage('success', 'История успешно деактивирована!');
      } catch (e) {
        console.error(e);
      } finally {
        await this.fetchStories();
        this.isLoading = false;
      }
    },
    async activateHistory(storyId) {
      this.isLoading = true;
      try {
        await storiesService.activateStory(storyId);
        showCustomMessage('success', 'История успешно активирована!');
      } catch (e) {
        console.error(e);
      } finally {
        await this.fetchStories();
        this.isLoading = false;
      }
    },
    clickMethod(row) {
      return row.item.isActive ? this.deactivateHistory(row.item.id) : this.activateHistory(row.item.id);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.bg-white thead th {
    text-align: center;
  }
  ::v-deep.bg-white .td-img {
    width: 15%;
    vertical-align: middle;
    text-align: center;
  }
  ::v-deep.bg-white .td-title {
    vertical-align: middle;
    text-align: center;
  }
  ::v-deep.bg-white .td-activate {
    width: 10%;
    vertical-align: middle;
    text-align: center;
  }
  ::v-deep.bg-white .td-button {
    width: 15%;
    vertical-align: middle;
    text-align: center;
  }
  ::v-deep.bg-grey tbody{
    background-color: rgb(245, 242, 242);
  }
.histories {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.history-list {
  height: 100%;
  transition: height 0.3s linear;
  box-sizing: border-box;
}
.crm-form-checkbox-text{
  vertical-align: sub;
}
.btn-text{
  font-size: 11px;
}
</style>
